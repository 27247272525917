exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-acknowledgements-tsx": () => import("./../../../src/pages/acknowledgements.tsx" /* webpackChunkName: "component---src-pages-acknowledgements-tsx" */),
  "component---src-pages-help-tsx": () => import("./../../../src/pages/help.tsx" /* webpackChunkName: "component---src-pages-help-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-information-about-swot-alt-tsx": () => import("./../../../src/pages/information/about-swot-alt.tsx" /* webpackChunkName: "component---src-pages-information-about-swot-alt-tsx" */),
  "component---src-pages-information-about-swot-tsx": () => import("./../../../src/pages/information/about-swot.tsx" /* webpackChunkName: "component---src-pages-information-about-swot-tsx" */),
  "component---src-pages-information-about-the-area-tsx": () => import("./../../../src/pages/information/about-the-area.tsx" /* webpackChunkName: "component---src-pages-information-about-the-area-tsx" */),
  "component---src-pages-information-about-the-roles-tsx": () => import("./../../../src/pages/information/about-the-roles.tsx" /* webpackChunkName: "component---src-pages-information-about-the-roles-tsx" */),
  "component---src-pages-information-background-info-tsx": () => import("./../../../src/pages/information/background-info.tsx" /* webpackChunkName: "component---src-pages-information-background-info-tsx" */),
  "component---src-pages-information-community-alt-tsx": () => import("./../../../src/pages/information/community-alt.tsx" /* webpackChunkName: "component---src-pages-information-community-alt-tsx" */),
  "component---src-pages-information-community-tsx": () => import("./../../../src/pages/information/community.tsx" /* webpackChunkName: "component---src-pages-information-community-tsx" */),
  "component---src-pages-information-development-options-tsx": () => import("./../../../src/pages/information/development-options.tsx" /* webpackChunkName: "component---src-pages-information-development-options-tsx" */),
  "component---src-pages-information-graph-cms-development-option-slug-alt-tsx": () => import("./../../../src/pages/information/{GraphCMS_DevelopmentOption.slug}-alt.tsx" /* webpackChunkName: "component---src-pages-information-graph-cms-development-option-slug-alt-tsx" */),
  "component---src-pages-information-graph-cms-development-option-slug-tsx": () => import("./../../../src/pages/information/{GraphCMS_DevelopmentOption.slug}.tsx" /* webpackChunkName: "component---src-pages-information-graph-cms-development-option-slug-tsx" */),
  "component---src-pages-introduction-tsx": () => import("./../../../src/pages/introduction.tsx" /* webpackChunkName: "component---src-pages-introduction-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-student-information-tsx": () => import("./../../../src/pages/student/information.tsx" /* webpackChunkName: "component---src-pages-student-information-tsx" */),
  "component---src-pages-student-stages-stage-1-stage-1-complete-tsx": () => import("./../../../src/pages/student/stages/stage-1/stage-1-complete.tsx" /* webpackChunkName: "component---src-pages-student-stages-stage-1-stage-1-complete-tsx" */),
  "component---src-pages-student-stages-stage-1-stage-1-landing-tsx": () => import("./../../../src/pages/student/stages/stage-1/stage-1-landing.tsx" /* webpackChunkName: "component---src-pages-student-stages-stage-1-stage-1-landing-tsx" */),
  "component---src-pages-student-stages-stage-1-stage-1-task-tsx": () => import("./../../../src/pages/student/stages/stage-1/stage-1-task.tsx" /* webpackChunkName: "component---src-pages-student-stages-stage-1-stage-1-task-tsx" */),
  "component---src-pages-student-stages-stage-2-stage-2-complete-tsx": () => import("./../../../src/pages/student/stages/stage-2/stage-2-complete.tsx" /* webpackChunkName: "component---src-pages-student-stages-stage-2-stage-2-complete-tsx" */),
  "component---src-pages-student-stages-stage-2-stage-2-landing-tsx": () => import("./../../../src/pages/student/stages/stage-2/stage-2-landing.tsx" /* webpackChunkName: "component---src-pages-student-stages-stage-2-stage-2-landing-tsx" */),
  "component---src-pages-student-stages-stage-2-stage-2-task-tsx": () => import("./../../../src/pages/student/stages/stage-2/stage-2-task.tsx" /* webpackChunkName: "component---src-pages-student-stages-stage-2-stage-2-task-tsx" */),
  "component---src-pages-student-stages-stage-3-stage-3-choose-options-tsx": () => import("./../../../src/pages/student/stages/stage-3/stage-3-choose-options.tsx" /* webpackChunkName: "component---src-pages-student-stages-stage-3-stage-3-choose-options-tsx" */),
  "component---src-pages-student-stages-stage-3-stage-3-complete-tsx": () => import("./../../../src/pages/student/stages/stage-3/stage-3-complete.tsx" /* webpackChunkName: "component---src-pages-student-stages-stage-3-stage-3-complete-tsx" */),
  "component---src-pages-student-stages-stage-3-stage-3-landing-tsx": () => import("./../../../src/pages/student/stages/stage-3/stage-3-landing.tsx" /* webpackChunkName: "component---src-pages-student-stages-stage-3-stage-3-landing-tsx" */),
  "component---src-pages-student-stages-stage-3-stage-3-swot-example-tsx": () => import("./../../../src/pages/student/stages/stage-3/stage-3-swot-example.tsx" /* webpackChunkName: "component---src-pages-student-stages-stage-3-stage-3-swot-example-tsx" */),
  "component---src-pages-student-stages-stage-3-stage-3-swot-tsx": () => import("./../../../src/pages/student/stages/stage-3/stage-3-swot.tsx" /* webpackChunkName: "component---src-pages-student-stages-stage-3-stage-3-swot-tsx" */),
  "component---src-pages-student-stages-stage-4-stage-4-choose-options-tsx": () => import("./../../../src/pages/student/stages/stage-4/stage-4-choose-options.tsx" /* webpackChunkName: "component---src-pages-student-stages-stage-4-stage-4-choose-options-tsx" */),
  "component---src-pages-student-stages-stage-4-stage-4-complete-tsx": () => import("./../../../src/pages/student/stages/stage-4/stage-4-complete.tsx" /* webpackChunkName: "component---src-pages-student-stages-stage-4-stage-4-complete-tsx" */),
  "component---src-pages-student-stages-stage-4-stage-4-feasibility-tsx": () => import("./../../../src/pages/student/stages/stage-4/stage-4-feasibility.tsx" /* webpackChunkName: "component---src-pages-student-stages-stage-4-stage-4-feasibility-tsx" */),
  "component---src-pages-student-stages-stage-4-stage-4-landing-tsx": () => import("./../../../src/pages/student/stages/stage-4/stage-4-landing.tsx" /* webpackChunkName: "component---src-pages-student-stages-stage-4-stage-4-landing-tsx" */),
  "component---src-pages-student-stages-stage-5-stage-5-business-plan-tsx": () => import("./../../../src/pages/student/stages/stage-5/stage-5-business-plan.tsx" /* webpackChunkName: "component---src-pages-student-stages-stage-5-stage-5-business-plan-tsx" */),
  "component---src-pages-student-stages-stage-5-stage-5-complete-tsx": () => import("./../../../src/pages/student/stages/stage-5/stage-5-complete.tsx" /* webpackChunkName: "component---src-pages-student-stages-stage-5-stage-5-complete-tsx" */),
  "component---src-pages-student-stages-stage-5-stage-5-landing-tsx": () => import("./../../../src/pages/student/stages/stage-5/stage-5-landing.tsx" /* webpackChunkName: "component---src-pages-student-stages-stage-5-stage-5-landing-tsx" */),
  "component---src-pages-student-stages-stage-6-stage-6-completed-work-tsx": () => import("./../../../src/pages/student/stages/stage-6/stage-6-completed-work.tsx" /* webpackChunkName: "component---src-pages-student-stages-stage-6-stage-6-completed-work-tsx" */),
  "component---src-pages-student-stages-stage-6-stage-6-landing-tsx": () => import("./../../../src/pages/student/stages/stage-6/stage-6-landing.tsx" /* webpackChunkName: "component---src-pages-student-stages-stage-6-stage-6-landing-tsx" */),
  "component---src-pages-student-stages-stage-6-stage-6-presentation-tips-tsx": () => import("./../../../src/pages/student/stages/stage-6/stage-6-presentation-tips.tsx" /* webpackChunkName: "component---src-pages-student-stages-stage-6-stage-6-presentation-tips-tsx" */),
  "component---src-pages-student-stages-stage-7-stage-7-landing-tsx": () => import("./../../../src/pages/student/stages/stage-7/stage-7-landing.tsx" /* webpackChunkName: "component---src-pages-student-stages-stage-7-stage-7-landing-tsx" */),
  "component---src-pages-student-stages-stage-7-stage-7-presentation-tips-tsx": () => import("./../../../src/pages/student/stages/stage-7/stage-7-presentation-tips.tsx" /* webpackChunkName: "component---src-pages-student-stages-stage-7-stage-7-presentation-tips-tsx" */),
  "component---src-pages-student-stages-stage-8-stage-8-landing-tsx": () => import("./../../../src/pages/student/stages/stage-8/stage-8-landing.tsx" /* webpackChunkName: "component---src-pages-student-stages-stage-8-stage-8-landing-tsx" */),
  "component---src-pages-student-stages-stage-8-stage-8-task-tsx": () => import("./../../../src/pages/student/stages/stage-8/stage-8-task.tsx" /* webpackChunkName: "component---src-pages-student-stages-stage-8-stage-8-task-tsx" */),
  "component---src-pages-student-team-hub-tsx": () => import("./../../../src/pages/student/team-hub.tsx" /* webpackChunkName: "component---src-pages-student-team-hub-tsx" */),
  "component---src-pages-student-tsx": () => import("./../../../src/pages/student.tsx" /* webpackChunkName: "component---src-pages-student-tsx" */),
  "component---src-pages-tutor-add-students-tsx": () => import("./../../../src/pages/tutor/add-students.tsx" /* webpackChunkName: "component---src-pages-tutor-add-students-tsx" */),
  "component---src-pages-tutor-assessment-tsx": () => import("./../../../src/pages/tutor/assessment.tsx" /* webpackChunkName: "component---src-pages-tutor-assessment-tsx" */),
  "component---src-pages-tutor-create-team-tsx": () => import("./../../../src/pages/tutor/create-team.tsx" /* webpackChunkName: "component---src-pages-tutor-create-team-tsx" */),
  "component---src-pages-tutor-current-groups-tsx": () => import("./../../../src/pages/tutor/current-groups.tsx" /* webpackChunkName: "component---src-pages-tutor-current-groups-tsx" */),
  "component---src-pages-tutor-hub-tsx": () => import("./../../../src/pages/tutor/hub.tsx" /* webpackChunkName: "component---src-pages-tutor-hub-tsx" */),
  "component---src-pages-tutor-previous-group-tsx": () => import("./../../../src/pages/tutor/previous-group.tsx" /* webpackChunkName: "component---src-pages-tutor-previous-group-tsx" */),
  "component---src-pages-tutor-stages-stage-1-tutor-stage-1-submitted-tsx": () => import("./../../../src/pages/tutor/stages/stage-1/tutor-stage-1-submitted.tsx" /* webpackChunkName: "component---src-pages-tutor-stages-stage-1-tutor-stage-1-submitted-tsx" */),
  "component---src-pages-tutor-stages-stage-3-tutor-stage-3-submitted-tsx": () => import("./../../../src/pages/tutor/stages/stage-3/tutor-stage-3-submitted.tsx" /* webpackChunkName: "component---src-pages-tutor-stages-stage-3-tutor-stage-3-submitted-tsx" */),
  "component---src-pages-tutor-stages-stage-4-tutor-stage-4-submitted-tsx": () => import("./../../../src/pages/tutor/stages/stage-4/tutor-stage-4-submitted.tsx" /* webpackChunkName: "component---src-pages-tutor-stages-stage-4-tutor-stage-4-submitted-tsx" */),
  "component---src-pages-tutor-stages-stage-5-tutor-stage-5-submitted-tsx": () => import("./../../../src/pages/tutor/stages/stage-5/tutor-stage-5-submitted.tsx" /* webpackChunkName: "component---src-pages-tutor-stages-stage-5-tutor-stage-5-submitted-tsx" */),
  "component---src-pages-tutor-team-assessment-tsx": () => import("./../../../src/pages/tutor/team-assessment.tsx" /* webpackChunkName: "component---src-pages-tutor-team-assessment-tsx" */),
  "component---src-pages-tutor-technical-guide-tsx": () => import("./../../../src/pages/tutor/technical-guide.tsx" /* webpackChunkName: "component---src-pages-tutor-technical-guide-tsx" */),
  "component---src-pages-tutor-tsx": () => import("./../../../src/pages/tutor.tsx" /* webpackChunkName: "component---src-pages-tutor-tsx" */),
  "component---src-pages-tutor-tutor-guide-tsx": () => import("./../../../src/pages/tutor/tutor-guide.tsx" /* webpackChunkName: "component---src-pages-tutor-tutor-guide-tsx" */)
}

